<template>
    <div class="loading-container">
        <div class="loading-spinner" />
    </div>
</template>
<script lang="ts">
import Vue from '@/vueTyped';

export default Vue.extend({
    name: 'LoadingIndicator',
    components: { },
    data() {
    return {};
    },
    async created() {},
});
</script>
<style lang="postcss" scoped>
.loading-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.loading-container:before {
    content: '';
    z-index: 10;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.5);
}

.loading-spinner {
    z-index: 11;
    top: 15rem;
}

.loading-spinner:not(:required) {
  opacity: 1;
  overflow: hidden;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -4px;
  margin-top: -4px;
  text-indent: -9999px;
  display: inline-block;
  width: 8px;
  height: 8px;
  background: transparent;
  border-radius: 100%;
  box-shadow: #f7d257 -14px -14px 0 7px, #e0695f 14px -14px 0 7px, #5fc2c3 14px 14px 0 7px, #a1cd3c -14px 14px 0 7px;
  animation: dots-loader 5s infinite ease-in-out;
  transform-origin: 50% 50%;
  transform: scale(1) rotate(45deg);
  transition: 0.3s all;
}
@keyframes dots-loader {
  0% {
    box-shadow: #f7d257  -14px -14px 0 7px, #e0695f 14px -14px 0 7px, #5fc2c3 14px 14px 0 7px, #a1cd3c -14px 14px 0 7px;
  }

  8.33% {
    box-shadow: #f7d257  14px -14px 0 7px, #e0695f 14px -14px 0 7px, #5fc2c3 14px 14px 0 7px, #a1cd3c -14px 14px 0 7px;
  }

  16.67% {
    box-shadow: #f7d257  14px 14px 0 7px, #e0695f 14px 14px 0 7px, #5fc2c3 14px 14px 0 7px, #a1cd3c -14px 14px 0 7px;
  }

  25% {
    box-shadow: #f7d257  -14px 14px 0 7px, #e0695f -14px 14px 0 7px, #5fc2c3 -14px 14px 0 7px, #a1cd3c -14px 14px 0 7px;
  }

  33.33% {
    box-shadow: #f7d257 -14px -14px 0 7px, #e0695f -14px 14px 0 7px, #5fc2c3 -14px -14px 0 7px, #a1cd3c -14px -14px 0 7px;
  }

  41.67% {
    box-shadow: #f7d257 14px -14px 0 7px, #e0695f -14px 14px 0 7px, #5fc2c3 -14px -14px 0 7px, #a1cd3c 14px -14px 0 7px;
  }

  50% {
    box-shadow: #f7d257 14px 14px 0 7px, #e0695f -14px 14px 0 7px, #5fc2c3 -14px -14px 0 7px, #a1cd3c 14px -14px 0 7px;
  }

  58.33% {
    box-shadow: #f7d257 -14px 14px 0 7px, #e0695f -14px 14px 0 7px, #5fc2c3 -14px -14px 0 7px, #a1cd3c 14px -14px 0 7px;
  }

  66.67% {
    box-shadow: #f7d257 -14px -14px 0 7px, #e0695f -14px -14px 0 7px, #5fc2c3 -14px -14px 0 7px, #a1cd3c 14px -14px 0 7px;
  }

  75% {
    box-shadow: #f7d257 14px -14px 0 7px, #e0695f 14px -14px 0 7px, #5fc2c3 14px -14px 0 7px, #a1cd3c 14px -14px 0 7px;
  }

  83.33% {
    box-shadow: #f7d257 14px 14px 0 7px, #e0695f 14px -14px 0 7px, #5fc2c3 14px 14px 0 7px, #a1cd3c 14px 14px 0 7px;
  }

  91.67% {
    box-shadow: #f7d257 -14px 14px 0 7px, #e0695f 14px -14px 0 7px, #5fc2c3 14px 14px 0 7px, #a1cd3c -14px 14px 0 7px;
  }

  100% {
    box-shadow: #f7d257 -14px -14px 0 7px, #e0695f 14px -14px 0 7px, #5fc2c3 14px 14px 0 7px, #a1cd3c -14px 14px 0 7px;
  }
}
</style>
