<template>
    <!--eslint-disable-next-line vue/no-v-html-->
    <component :is="tag" class="markdown-output-root" :data-trusted="trusted" :data-contain-margins="containMargins" v-html="html" />
</template>

<script lang="ts">
import Vue from '@/vueTyped';
import { markdownToHtml } from '../util.markdown';

export default Vue.extend({
    props: {
        tag: {
            type: String,
            default : 'div'
        },

        value: {
            type: String,
            default: ''
        },

        inline: {
            type: Boolean,
            default: false,
        },

        trusted: {
            type: Boolean,
            default: false,
        },

        basicFormatting: {
            type: Boolean,
            default: false,
        },

        containMargins: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        html(): string {
            const { trusted, inline, basicFormatting } = this;
            return markdownToHtml(this.value, { trusted, inline, basicFormatting });
        },
    },
});
</script>

<style lang="postcss" scoped>
    .markdown-output-root[data-contain-margins] > :deep(*:first-child) {
        margin-top: 0;
    }

    .markdown-output-root[data-contain-margins] > :deep(*:last-child) {
        margin-bottom: 0;
    }

    /* We don't _really_ want folks adding headings to the page, right? */
    .markdown-output-root:not([data-trusted]) :deep(h1),
    .markdown-output-root:not([data-trusted]) :deep(h2),
    .markdown-output-root:not([data-trusted]) :deep(h3),
    .markdown-output-root:not([data-trusted]) :deep(h4),
    .markdown-output-root:not([data-trusted]) :deep(h5),
    .markdown-output-root:not([data-trusted]) :deep(h6) {
        font-size: inherit;
        font-weight: bold;
    }

    .markdown-output-root :deep(ol),
    .markdown-output-root :deep(ul) {
        margin-bottom: 16px; /* To match paragraphs */
    }
</style>
